<template>
  <!-- v-model="drawer"
      :dark="barColor !== 'rgba(38, 51, 82, 1), rgba(38, 51, 82, 0.9)'"-->
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :mini-variant="true"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    mobile-break-point="960"
    app
    width="260"
    v-bind="$attrs"
    elevation="2"
    class="ma-0"
    style="z-index: 200"
  >
    <!-- <template v-slot:img="props">
      <v-img
        :gradient="`to bottom, rgba(0, 0, 0, .6), rgba(0, 0, 0, .3)`"
        v-bind="props"
      />
    </template> -->
    <br /><br />
    {{ activeRoute }}
    <v-row>
      <v-col class="ma-0 pa-0">
        <v-list dense nav class="ma-0 pa-0">
          <v-list-item
            class="ma-0 pa-0"
            style="background-color: white !important"
          >
          </v-list-item>
        </v-list>

        <v-list>
          <v-list-item
            v-for="item in publicItems"
            :key="item.to"
            link
            :item="item"
            :to="item.to"
            :class="{ 'primary--text': $route.path === item.to }"
            class="mx-1"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </v-navigation-drawer>
</template>

<script>
// Utilities
import { mapState } from "vuex";
import roleIds from "../../../../libraries/strapi/rolesIds";

export default {
  name: "DashboardCoreDrawer",

  props: {
    expandOnHover: {
      type: Boolean,
      default: true,
    },
  },

  data: () => ({
    activeRoute: null,
    imageUrlLogo: "@/assets/img/logoDark.png",
    //  "https://mxwqlouaaupkgunfdbku.supabase.co/storage/v1/object/public/test/logoNewStMarie-removebg.png",
  }),
  created() {},

  mounted() {
    this.$forceUpdate();
  },

  watch: {
    $route(to, from) {
      this.activeRoute = to.path; // Update the active route when route changes
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    modeHomolg() {
      return `${process.env.VUE_APP_ENV}` == "HOMOLOG";
    },

    ...mapState(["barColor", "barImage"]),
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit("SET_DRAWER", val);
      },
    },
    images: function () {
      var images = this.$session.get("images");
      if (images) return images;
      else
        return {
          logo: "https://mxwqlouaaupkgunfdbku.supabase.co/storage/v1/object/public/test/logoNewStMarie-removebg.png",
          logoWhite:
            "https://mxwqlouaaupkgunfdbku.supabase.co/storage/v1/object/public/test/logoNewStMarie-removebg.png",
        };
    },
    items: function () {
      var items = [];
      return items;
    },
    publicItems: function () {
      // var publicItems = [];
      // publicItems.push(
      //   {
      //     icon: "mdi-account",
      //     title: "Meus dados",
      //     to: "/account",
      //   },
      //   {
      //     icon: "mdi-view-dashboard ",
      //     title: "Dashboard",
      //     to: "/dashboard",
      //   },
      //   {
      //     icon: "mdi-camera-timer",
      //     title: "SLA",
      //     to: "/sla",
      //   },
      //   {
      //     icon: "mdi-ferry",
      //     title: "Embarques",
      //     to: "/shippings",
      //   },
      //   {
      //     icon: "mdi-currency-usd",
      //     title: "Financeiro",
      //     to: "/finance",
      //   },

      //   {
      //     icon: "mdi-bell-cog",
      //     title: "Notificações",
      //     to: "/alerts",
      //   }
      // );

      let routes = [];
      if (this.user?.role?.id == roleIds.integradorSolar)
        routes = [
          {
            icon: "mdi-account",
            title: "Meus dados",
            to: "/account",
          },
          {
            icon: "mdi-view-dashboard ",
            title: "Dashboard",
            to: "/dashboard",
          },
        ];
      else
        routes = [
          {
            icon: "mdi-account",
            title: "Meus dados",
            to: "/account",
          },
          {
            icon: "mdi-view-dashboard ",
            title: "Dashboard",
            to: "/dashboard",
          },
          {
            icon: "mdi-camera-timer",
            title: "SLA",
            to: "/sla",
          },
          {
            icon: "mdi-ferry",
            title: "Embarques",
            to: "/shippings",
          },
          {
            icon: "mdi-currency-usd",
            title: "Financeiro",
            to: "/finance",
          },

          {
            icon: "mdi-bell-cog",
            title: "Notificações",
            to: "/alerts",
          },
        ];

      //Admin e Manager
      if (
        this.user?.role?.id == roleIds.admin ||
        this.user?.role?.id == roleIds.manager ||
        this.user?.team?.uid == "importation"
      ) {
        routes.push({
          icon: "mdi-currency-usd",
          title: "Limites de crédito",
          to: "/enterprises",
        });
      }
      if (
        this.user?.role?.id == roleIds.admin ||
        this.user?.role?.id == roleIds.manager ||
        this.user?.team?.uid == "distribution"
      ) {
        routes.push({
          icon: "mdi-currency-usd",
          title: "Limites de crédito - Distribuição",
          to: "/enterprises-distribution",
        });
      }

      if (
        this.user?.role?.id == roleIds.admin ||
        this.user?.role?.id == roleIds.manager
      ) {
        routes.push({
          icon: "mdi-file-document-check",
          title: "Fechamento de processos",
          to: "/fechamento",
        });
      }
      return routes;
    },
  },

  methods: {
    mapItem(item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: this.$t(item.title),
      };
    },
  },
};
</script>
<style></style>
